import http from '@/services/http-client';
/* import { formatSuperAdminUrl as formatUrl } from '@/services/helpers/super-admin'; */
import routes from '@/../config/env';

const { betTicker } = routes;

export const createBetTicker = () => http({
  method: 'post',
  url: `${betTicker}/bet-ticker/configuration`,
  data: {
    betTickerName: 'New Ticker',
    filters: [],
    inactiveFilters: [],
    enabled: true,
  },
});

export const renameBetTicker = (data) => http({
  method: 'post',
  url: `${betTicker}/bet-ticker/configuration/rename`,
  data: {
    betTickerId: data.betTickerId,
    betTickerName: data.name,
  },
});

export const duplicateBetTicker = (betTickerId) => http({
  method: 'post',
  url: `${betTicker}/bet-ticker/configuration/${betTickerId}/duplicate`,
});

export const deleteBetTicker = (betTickerId) => http({
  method: 'delete',
  url: `${betTicker}/bet-ticker/configuration/${betTickerId}`,
});

export const updateBetTickerHighlights = (betTickerId, highlights) => http({
  method: 'post',
  url: `${betTicker}/bet-ticker/configuration/highlights`,
  data: {
    betTickerId,
    highlights,
  },
});

export const updateBetTicker = (betTickerPayload) => http({
  method: 'put',
  url: `${betTicker}/bet-ticker/configuration`,
  data: betTickerPayload,
});

export const flag = (betId) => http({
  method: 'post',
  url: `${betTicker}/bet/${betId}/flag`,
});

export const unflag = (betId) => http({
  method: 'post',
  url: `${betTicker}/bet/${betId}/unflag`,
});
