import { createRouter, createWebHistory } from 'vue-router';
import { each, includes } from 'lodash';
import { useRouteTitle } from '@/utils/route-title';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import routes from './routes';
import envRoutes from '@/../config/env';

const { demoUrl } = envRoutes;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${useRouteTitle(to)} - Huddle OS`;
  const jwtToken = localStorage.getItem('userToken');
  const huddleRoles = localStorage.getItem('huddleRoles');
  const { isSuperAdmin, client } = getSuperAdminData();

  if (!jwtToken && !to.meta.login) {
    next('/login');
  } else {
    if (to.meta.login) {
      next();
      return;
    }
    if (includes(huddleRoles, 'DEMO_USER') && !includes(huddleRoles, ',') && window) { // 2nd check is to make sure there are no other roles as they are in string separated by comma
      window.location.href = demoUrl;
      return;
    }
    const { allowedUserRoles } = to.meta;
    const userRoles = huddleRoles ? huddleRoles.split(',') : [];
    let userAllowedRoute = false;
    each(userRoles, (role) => {
      if (includes(allowedUserRoles, role)) userAllowedRoute = true;
    });
    if (!userAllowedRoute) {
      next('/');
      return;
    }

    // Covering the case after user login.
    // Here we set the initial value for the query client param.
    // We set it here because we need to have it inside the query params before we hit the first network request
    if (isSuperAdmin && !to.query.client) {
      const { query } = to;
      next({
        ...to,
        query: {
          ...query,
          client,
        },
      });
      return;
    }

    if (to.name !== from.name && !to.query.sport && from.query.sport) {
      const { sport } = from.query;
      const { query } = to;
      next({
        ...to,
        query: {
          ...query,
          sport,
        },
      });
      return;
    }

    next();
  }
});

export default router;
