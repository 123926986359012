import operations from './operations';
import productAdmin from './product-admin';

export default [
  {
    path: '/',
    redirect: {
      name: 'events',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/login/Login'),
    meta: {
      title: 'Login',
      layout: 'public',
      login: true,
    },
    children: [
      {
        path: '/password-reset',
        name: 'password-reset',
        component: () => import('@/components/login/ResetPasswordForm'),
      },
      {
        path: '/password-change',
        name: 'password-change',
        component: () => import('@/components/login/ChangePasswordForm'),
      },
    ],
  },
  {
    path: '/forbidden_access',
    name: 'forbidden_access',
    component: () => import('@/components/common/ForbiddenAccess'),
  },
  {
    path: '/operations',
    children: operations,
  },
  {
    path: '/product-admin',
    children: productAdmin,
  },
];
